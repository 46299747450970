import React, { useState, forwardRef } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";

export const PasswordInput = forwardRef(({ error, ...rest }, ref) => {
  const [view, setView] = useState(false);

  return (
    <div className="mb-4 position-relative">
      <div className="position-absolute end-0  cursor-pointer me-3 eyeWrapper ">
        {!view ? (
          <BsEye onClick={() => setView(true)} color="#555555" size={18} />
        ) : (
          <BsEyeSlash onClick={() => setView(false)} color="#555555" size={18} />
        )}
      </div>
      <input
        ref={ref}
        {...rest}
        type={!view ? "password" : "text"}
        className="w-100 px-3 py-2 border rounded-4 form-control "
      />
      {error && <small className="text-danger mt-1">{error}</small>}
    </div>
  );
});
