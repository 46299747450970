import React, { forwardRef } from "react";

export const InputField = forwardRef(({ error, className, ...rest }, ref) => {
  return (
    <>
     <div className={`mb-3 ${className}`}>
      <input
        ref={ref}
        {...rest}
        className={`w-100 px-3 py-2 border rounded-4 form-control ${className}`}
      />
         {error && <small className="text-danger mt-1">{error}</small>}
      </div>
   
    </>
  );
});


