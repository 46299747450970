import React from "react";

export const SecondaryButton = ({ btn_text, type, onClick, className ,border_radius}) => {
  return (
    <button
      className={`w-100 border-none bg-greenish text-white py-2 ${
        border_radius ? border_radius : "rounded-4"
      } btn fw-bold fs-18 ${className}`}
      type={type ? type : `button`}
      onClick={onClick}
    >
      {btn_text}
    </button>
  );
};
