import React from "react";

const SubHeading = ({ text, className, fontSize, paddingT, paddingB }) => {
  return (
    <p
      className={` ${fontSize || "fs-2"} fw-bold ${
        paddingB || "pb-lg-4 pb-2"
      }  ${paddingT || "pt-3 pt-xl-0"}  m-0 ${className} `}
    >
      {text}
    </p>
  );
};

export default SubHeading;
