"use client";
import React from "react";
import { LoginForm } from "../../src/components/organisms/login-form/index";

import { Layout } from "../../src/components/organisms/layout/Layout";
const Login = () => {
  return (
    <Layout>
      <LoginForm />
    </Layout>
  );
};

export default Login;
