import * as Yup from "yup";


export const loginSchema = Yup.object().shape({
  username: Yup.string()
    .email("Invalid email address.")
    .required()
    .label("Email Address"),
  password: Yup.string()
    .required()
    .min(6, "Password should have more than 6 characters")
    .label("Password"),
});

export const signUpSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address.")
    .required()
    .label("Email Address"),
  first_name: Yup.string().required().label("First Name"),
  mobile_number: Yup.string().required().label("Phone Number"),
  last_name: Yup.string().required().label("Last Name"),
  password: Yup.string()
    .required()
    .min(6, "Password should have more than 6 characters")
    .label("Password"),
  confirm_password: Yup.string()
    .required()
    .oneOf([Yup.ref("password")], "Password does not match")
    .label("Confirm Password"),
});

export const changePassSchema = Yup.object().shape({
  oldPass: Yup.string().required("Old Password is required"),
  newPass: Yup.string().required("New Password is required").min(6, "Password should have more than 6 characters"),
  confirmPass: Yup.string().required("Confirm Password is required").oneOf([Yup.ref("newPass")], "Passwords must match"),
});