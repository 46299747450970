"use client";
import React, { useEffect, useState } from "react";
import SubHeading from "../../atoms/sub_heading";
import { useRouter } from "next/navigation";
import { FormProvider, useForm } from "react-hook-form";
import { loginSchema } from "../../../validation-schema";
import { yupResolver } from "@hookform/resolvers/yup";
import Link from "next/link";
import { Container } from "react-bootstrap";
import { InputField } from "../../atoms/input_field";
import { PasswordInput } from "../../atoms/password_field";
import { SecondaryButton } from "../../atoms/secondary_button";
import {
  BASE_URL,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
} from "../../../constants/common";
import { decodeToken } from "../../../utils/jwt";
import { ADMIN_ROUTES, Routes } from "../../../constants/routes";
import { toast } from "sonner";

export const LoginForm = () => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const methods = useForm({ resolver: yupResolver(loginSchema) });
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = methods;

  useEffect(() => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (!token) return;
    const decoded = decodeToken(token);
    if (decoded.is_verified) {
      router.push(ADMIN_ROUTES.DASHBOARD.absolutePath);
    } else {
      router.push(Routes.VERIFY.absolutePath);
    }
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
    };
    fetch(`${BASE_URL}/auth/api/login/`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res.access) {
          toast.success("Login successful!");
          const decoded = decodeToken(res.access);
          if (decoded.is_verified) {
            localStorage.setItem(ACCESS_TOKEN, res.access);
            localStorage.setItem(REFRESH_TOKEN, res.refresh);
            router.push(ADMIN_ROUTES.DASHBOARD.absolutePath);
          } else {
            setIsVerify(true);
          }
        } else {
          toast.error(res.detail);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong");
      });
  };
  const handleSendVerify = () => {
    const USERNAME = watch("username");
    setLoading(true);
    const data = {
      user_email: `${USERNAME}`,
    };
    fetch(`${BASE_URL}/auth/api/resend-email/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res.success) {
          toast.success("verification sent successfully!");
        } else {
          toast.error(res.message || "Failed to send email verification");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.message || "Something went wrong");
      });
  };

  const LOGIN_BTN = "Login";
  return (
    <div className="container-fluid d-flex justify-content-center align-items-center  my-5  login_wrapper ">
      <Container>
        <div
          className="p-lg-5 p-4  rounded-3 login_container  mx-auto"
          style={{ maxWidth: "500px" }}
        >
          {isVerify && (
            <p
              className={`
      text-center 
      m-0 `}
            >
              Your account is currently inactive. Please {""}{" "}
              <span
                className="fw-bold text-greenish cursor-pointer"
                onClick={handleSendVerify}
              >
                {" "}
                Click here{" "}
              </span>{" "}
              {""} to send a verification email and activate your account.
            </p>
          )}
          <SubHeading
            text="Login"
            paddingB={"pb-3"}
            className={"text-center newsReaderFont  text-greenish"}
          />
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputField
                type="email"
                placeholder="Enter your email address"
                {...register("username", { required: true })}
                error={errors.username?.message}
              />
              <PasswordInput
                {...register("password", { required: true })}
                error={errors.password?.message}
                placeholder="Enter your password"
              />

              <SecondaryButton 
              className={"rounded-4"}
                type="submit"
                btn_text={loading ? "Loading..." : LOGIN_BTN}
              />
            </form>
          </FormProvider>

          <div className="text-center mt-3 fs-6 ">
            <span>
              Not a member?
              <Link
                className="fw-bold cursor-pointer ms-2  text-greenish"
                href="/register"
              >
                <u>Register</u>
              </Link>
            </span>
          </div>
          <div className="text-center mt-3 fs-6 ">
            <span>
              Forgot password?
              <Link
                className="fw-bold cursor-pointer ms-2  text-greenish"
                href="/forget-password"
              >
                <u>Click here</u>
              </Link>
            </span>
          </div>
        </div>
      </Container>
    </div>
  );
};
